import { Dialog, Switch, Transition } from "@headlessui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Carousel from "../base/Carousel";
import { toast } from "react-toastify";
import { ReactComponent as UncheckIcon } from "../../assets/radio-check.svg";
import { ReactComponent as CheckIcon } from "../../assets/radio-uncheck.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/green_checkmark.svg";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import { RadioGroup } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkoutSubscription,
  downgradeSubscription,
  loadQuestionnaireAnswers,
  submitQuestionnaireAnswers,
  updateShowQuestionnaireModal,
  upgradeSubscription,
} from "../../reducers/userReducer";
import { updateShowOutOfCreditModal } from "../../reducers/formReducer";
import plans from "../../data/plans";
import Commerce from "../../services/eComService";
import HighDemand from "../../assets/high-demand-image.png";

const IMAGES = {
  highDemand: HighDemand,
}

const OutOfCredits = (props) => {
  const open = useSelector((state) => state.form.showOutOfCreditModal);
  const title = useSelector((state) => state.form.outOfCreditTitle);
  const subtitle = useSelector((state) => state.form.outOfCreditSubtitle);
  const image = useSelector((state) => state.form.outOfCreditImage);

  const user = useSelector((state) => state.userSlice.user);
  const current_plan_ind = user?.plan?.productId
    ? plans.findIndex(
        (el) =>
          [el.monthlyPriceId, el.yearlyPriceId].indexOf(user?.plan?.productId) >
          -1
      )
    : -1;

  const [selected, setSelected] = useState(
    plans[
			Math.max(
				current_plan_ind, 
				localStorage.getItem('selectPlan') || 1
			)
		]
  );
  const [isMonthly, setMonthly] = useState(true);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(updateShowOutOfCreditModal(false));
  };

  const getSubscription = async (price_id) => {
    try {
      const handler =
        current_plan_ind === -1
          ? checkoutSubscription
          : current_plan_ind <
            plans.findIndex(
              (el) => el.monthlyPriceId === selected.monthlyPriceId
            )
          ? upgradeSubscription
          : downgradeSubscription;
      let price = isMonthly ? selected.monthlyPriceId : selected.yearlyPriceId;
      const response = await dispatch(handler({ price })).unwrap();
      // track
      Commerce.track(
        "begin_checkout_a",
        {
          plan: props,
          ...response,
        },
        {
          // user: userData,
        }
      );

      if (
        current_plan_ind <
        plans.findIndex((el) => el.monthlyPriceId === selected.monthlyPriceId)
      ) {
        toast("Subscription downgraded", {
          position: toast.POSITION.BOTTOM_RIGHT,
          theme: "dark",
          autoClose: 2000,
        });
      }
      window.open(response.callback, "_self");
    } catch (e) {
      toast(e.response?.data?.details || `An error occured.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog open={open} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto custom-scroll">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`${image ? 'w-[650px]' : 'w-[550px]'} max-w-11/12 flex flex-col gap-6 justify-between items-center overflow-hidden relative bg-app-black rounded-md py-8 ${image ? 'px-8' : ''}`}
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="w-8 aspect-square rounded-full absolute top-2 right-3 bg-black bg-opacity-60 hover:bg-opacity-90 transition-all duration-200 flex items-center justify-center"
                  onClick={onClose}
                >
                  <CloseIcon className="w-6 h-6" />
                </button>
                {image && (
                  <div className="bg-black w-[calc(100%+4rem)] flex items-center justify-center -mt-8 -mx-8">
                    <img src={IMAGES[image]} alt="Out of credit"/>
                  </div>
                )}
                <div className={`flex flex-col self-stretch items-center gap-4 ${image ? 'px-6' : ''}`}>
                  <h2 className="text-white text-center text-2xl font-bold font-montserrat">
                    {title}
                  </h2>
                  <h3 className="text-modal-title text-center text-sm font-light font-montserrat">
                    {subtitle}
                  </h3>
                </div>
                <Switch
                  name="terms-of-service"
                  // defaultChecked={true}
                  checked={isMonthly}
                  onChange={setMonthly}
                  as={React.Fragment}
                >
                  {({ checked }) => (
                    <button
                      className={`bg-switch-bg relative inline-flex items-center rounded-full w-10/12 py-3`}
                    >
                      <span
                        className={`${
                          checked ? "left-1/2" : "left-0"
                        } inline-block h-full w-1/2 transform rounded-full bg-button-purple transition-all duration-200 absolute `}
                      />

                      <span
                        className={`${
                          checked ? " text-switch-des" : "text-white"
                        } text-sm font-normal font-inter text-center basis-1/2 z-10`}
                      >
                        Pay Yearly
                        <span className="text-sm text-white font-bold font-inter text-center mx-2">
                          Up to 20% off
                        </span>
                      </span>
                      <span className="text-sm text-white font-bold font-inter text-center  basis-1/2 z-10">
                        Pay Monthly
                      </span>
                    </button>
                  )}
                </Switch>

                <RadioGroup
                  value={selected}
                  onChange={setSelected}
                  className="w-10/12"
                >
                  <div className="space-y-2 flex flex-col mx-auto ">
                    {plans.slice(1).map((plan) => (
                      <RadioGroup.Option
                        key={plan.name}
                        value={plan}
                        className={({ active, checked }) => `
				${
          checked
            ? "bg-app-bg-gray bg-opacity-75   border-app-green border-[2px]"
            : "bg-transparent border border-radio-border"
        }
				relative flex cursor-pointer rounded-2xl px-3 py-3 shadow-md focus:outline-none  border-solid text-white`}
                      >
                        {({ active, checked }) => (
                          <>
                            {!checked ? (
                              <CheckIcon className="w-6 h-6 mr-2" />
                            ) : (
                              <UncheckIcon className="w-6 h-6 mr-2" />
                            )}
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium text-start text-white flex-grow`}
                            >
                              {plan.name} Subscription
                              <span className="font-roboto font-black text-radio-color">
                                {" "}
                                {plan.creditText}
                              </span>
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline text-white text-base font-inter mx-2`}
                            >
                              {!isMonthly && (
                                <span className="ui-checked:text-radio-des  text-radio-des-off line-through mx-1">
                                  ${plan.priceMonthly}
                                </span>
                              )}
                              {isMonthly
                                ? `$${plan.priceMonthly}`
                                : `$${plan.priceYearly}`}
                              <span className="ui-checked:text-radio-des  text-radio-des-off">
                                {" / "}
                                month
                                {/* {isMonthly ? "month" : "year"} */}
                              </span>
                            </RadioGroup.Description>
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>

								{!image && (
									<div className="w-[300px] space-y-3 my-4 ">
										{selected.perks.map(perk => (
											<li className="flex items-center gap-2 text-perk-color font-medium text-base font-inter">
												{perk.type === 'perk' ? <CheckmarkIcon /> : <CloseIcon className="[&_path]:!stroke-chevron-icon [&_path]:!stroke-1"/> }
												<span className={`font-inter font-medium text-sm leading-[21px] ${
													perk.type === 'perk' ? '' : 'text-select-label-disabled'}`}>
													{perk.title}
													{perk.subtitle && (
														<span className="text-xs text-[#9093A6] text-opacity-60 mx-2">
															{perk.subtitle}
														</span>
													)}
												</span>
											</li>
										))}
									</div>
								)}
                <button
                  className="rounded-lg py-3 bg-button-purple w-[210px] text-white font-inter font-semibold text-sm"
                  onClick={getSubscription}
                >
                  Subscribe to basic
                </button>
                <button
                  className="text-button-purple font-medium font-inter text-xs "
                  onClick={() => setMonthly(!isMonthly)}
                >
                  Switch to {isMonthly ? "yearly" : "monthly"} billing
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OutOfCredits;
