import environment from '../helpers/envProvider';

const PAYMENT_ENVIRONMENT = environment("REACT_APP_PAYMENT_ENVIRONMENT") || "production";

const plans = [
  {
    priceMonthly: 0,
    priceYearly: 0,
    name: 'Free',
    description: 'Perfect for experimentation & design ideation.',
    perks: [
			{ title: '40 credits / month', subtitle: "~20 designs", type: 'perk' },
			{ title: '2 concurrent image generation jobs', type: 'perk' },
			{ title: 'Commercial license', type: 'disadvantage' },
			{ title: 'Optional Credit top ups', type: 'disadvantage' },
			{ title: 'Private Generations', type: 'disadvantage' },
			{ title: 'Vectorization Access', type: 'disadvantage' },
			{ title: 'Creative Upscaler', type: 'disadvantage' },
			{ title: 'Image Background Removal', type: 'disadvantage' },
			{ title: 'Watermark free designs', type: 'disadvantage' },
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: '', // Testing
      yearlyPriceId: '', // Testing
    } : {
      monthlyPriceId: '', // Production
      yearlyPriceId: '', // Production
    }),
		credits: 40,
    paidFeatureUsage: {
      vectorize: 0,
      "remove-bg": 0,
    },
  },

  {
    priceMonthly: 24,
    priceYearly: 20,
    name: 'Basic',
    description: 'Perfect for experimentation & design ideation.',
    creditText: '1,000 Credits',
    perks: [
			{ title: '1000 monthly credits', subtitle: '~500 designs', type: 'perk' },
			{ title: 'Commercial license', type: 'perk' },
			{ title: 'Optional Credit top ups', type: 'perk' },
			{ title: 'Private Generations', type: 'perk' },
			{ title: '4 concurrent image generation jobs', type: 'perk' },
			{ title: '50 Vectorization Access', type: 'perk' },
			{ title: '4x Creative Upscaler', subtitle: '100 2x upscales',  type: 'perk' },
			{ title: '50 Image Background Removal', type: 'perk' },
			{ title: 'Watermark free designs', type: 'perk' },
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: 'price_1NLApHHPReHZNX9KYDqGjSGV', // Testing
      yearlyPriceId: 'price_1NLApzHPReHZNX9KoEYkxTYy', // Testing
    } : {
      monthlyPriceId: 'price_1NL3u3HPReHZNX9KMVcDSvCC', // Production
      yearlyPriceId: 'price_1NLAqXHPReHZNX9K4g2cpcfO', // Production
    }),
		credits: 1000,
    paidFeatureUsage: {
      'upscale-2x': 100,
      'upscale-4x': 0,
      vectorize: 50,
      "remove-bg": 50,
    },
  },

  {
    priceMonthly: 49,
    priceYearly: 40,
    name: 'Pro',
    description: 'Perfect for freelance designers and creators.',
    creditText: '2,500 Credits',
    perks: [
			{ title: '2500 monthly credits', subtitle: '~1250 designs', type: 'perk' },
			{ title: 'Commercial license', type: 'perk' },
			{ title: 'Optional Credit top ups', type: 'perk' },
			{ title: 'Private Generations', type: 'perk' },
			{ title: '4 concurrent image generation jobs', type: 'perk' },
			{ title: '100 Vectorization Access', type: 'perk' },
			{ title: '4x Creative Upscaler', subtitle: '250 2x or 85 4x',  type: 'perk' },
			{ title: '100 Image Background Removal', type: 'perk' },
			{ title: 'Watermark free designs', type: 'perk' },
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: 'price_1NRd68HPReHZNX9KQDXQDfmh', // Testing
      yearlyPriceId: 'price_1NRd68HPReHZNX9K9qV1xOSp', // Testing
    } : {
      monthlyPriceId: 'price_1NLAswHPReHZNX9KQP9rPqnu', // Production
      yearlyPriceId: 'price_1NLAt8HPReHZNX9KuDwDPrxl', // Production
    }),
		credits: 2500,
    paidFeatureUsage: {
      'upscale-2x': 250,
      'upscale-4x': 85,
      vectorize: 100,
      "remove-bg": 100,
    },
  },

  {
    priceMonthly: 99,
    priceYearly: 82.5,
    name: 'Elite',
    description: 'Perfect for businesses & agencies producing at scale.',
    creditText: '10,000 Credits',
    perks: [
			{ title: '10,000 monthly credits', subtitle: '~5000 designs', type: 'perk' },
			{ title: 'Commercial license', type: 'perk' },
			{ title: 'Optional Credit top ups', type: 'perk' },
			{ title: 'Private Generations', type: 'perk' },
			{ title: '4 concurrent image generation jobs', type: 'perk' },
			{ title: '200 Vectorization Access', type: 'perk' },
			{ title: '4x Creative Upscaler', subtitle: '1k 2x or 300 4x',  type: 'perk' },
			{ title: '250 Image Background Removal', type: 'perk' },
			{ title: 'Watermark free designs', type: 'perk' },
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: 'price_1QVwpRHPReHZNX9K1JiS0vdT', // Testing
      yearlyPriceId: 'price_1QVwcAHPReHZNX9KFv7LioT4', // Testing
    } : {
      monthlyPriceId: 'price_1QVwixHPReHZNX9Ky5lNdYiX', // Production
      yearlyPriceId: 'price_1QVwjAHPReHZNX9KwfMb2yqa', // Production
    }),
		credits: 10000,
    paidFeatureUsage: {
      'upscale-2x': 1000,
      'upscale-4x': 300,
      vectorize: 250,
      "remove-bg": 250,
    },
  },
]

export default plans;
