import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ReactComponent as CheckmarkIcon } from '../assets/green_checkmark.svg';
import { ReactComponent as CloseIcon } from "../assets/CloseIcon.svg";
import { checkoutCredits, checkoutSubscription, downgradeSubscription, upgradeSubscription } from '../reducers/userReducer';

import Commerce from '../services/eComService';
import { useMemo } from 'react';
import plans from '../data/plans';

function Plan(props) {
  const { plan, billingPeriod, planInd, onCancel } = props;
  const user = useSelector((state) => state.userSlice.user);
  const isLoading = useSelector((state) => state.userSlice.isLoading);
  const dispatch = useDispatch();

	const current_plan_ind = user?.plan?.productId ? plans.findIndex((el) => [el.monthlyPriceId, el.yearlyPriceId].indexOf(user?.plan?.productId) > -1) : -1;

  const getSubscription = async () => {
		if (plan.name === 'Free') {
			onCancel();
			return ;
		}
		const handler = current_plan_ind === -1 ? checkoutSubscription : (
			current_plan_ind < planInd ? 
				upgradeSubscription :
				downgradeSubscription
		)
    try {
      const price = (billingPeriod === "Monthly") ? plan.monthlyPriceId : plan.yearlyPriceId
      const response = await dispatch(handler({ price })).unwrap()
      // track
      Commerce.track('begin_checkout_a',{
        plan: props,
        ...response
      },{
        // user: userData,
      })

			if (current_plan_ind > planInd) {
				// toast("Subscription downgraded", {
				// 	position: toast.POSITION.BOTTOM_RIGHT,
				// 	theme: 'dark',
				// 	autoClose: 2000
				// });
			}

      window.open(response.callback, '_self');
    } catch(e) {
			toast(e.response?.data?.details || "An error occured.", {
				position: toast.POSITION.BOTTOM_RIGHT,
				theme: 'dark',
				autoClose: 2000
			});
    }
  }

  return (
    <div className="bg-[#1C1C24] rounded-[20px] px-[25px] py-[46px] flex flex-col gap-2">
      <p className="font-inter ">
        <span className="flex items-end justify-center">
          {
            billingPeriod === 'Yearly' && plan.priceMonthly !== 0
            ? (
              <span
                className="font-montserrat font-medium text-[28px] line-through mr-[8px] text-white items-end flex leading-none monthly-price"
              >
                ${plan.priceMonthly}
              </span>
            )
            : (
              null
            )
          }

          <span className="title-gradient flex items-end justify-center mr-[3px]">
            <span className="font-bold text-[16px] mr-[3px]">$</span>
            <span className="text-[40px] font-montserrat font-bold leading-none yearly-price">
              {billingPeriod === 'Monthly' ? plan.priceMonthly : plan.priceYearly}
            </span>
          </span>
          <span className="text-[16px] text-[#A3A3A3]">/mo</span>
        </span>
      </p>

      <p className="font-inter text-[21px] leading-[25px] font-bold my-[8px] text-[#FAFAFB] text-center">
        {plan.name}
      </p>

      <p
        className="text-[#7D7F83] font-inter text-[16px] leading-[24px] pb-[18px] border-b-[1px] border-[#26272C] text-center ">
        {plan.description}
      </p>

      <ul className="flex flex-col gap-[15px] ">
        {plan.perks.map(perk => (
          <li className="flex gap-[12px] items-center">
						{perk.type === 'perk' ? <CheckmarkIcon /> : <CloseIcon className="[&_path]:!stroke-chevron-icon [&_path]:!stroke-1"/> }
            <span className={`font-inter font-medium text-sm leading-[21px] ${
							perk.type === 'perk' ? 'text-[#9093A6]' : 'text-select-label-disabled'}`}>
              {perk.title}
							{perk.subtitle && (
								<span className="text-xs text-[#9093A6] text-opacity-60 mx-2">
									{perk.subtitle}
								</span>
							)}
            </span>
          </li>
        ))}
      </ul>
			<div className="flex-grow"/>

			{current_plan_ind === planInd ? (
				<button
					type="submit"
					className="get-early-access-button bg-divider rounded-[6px] h-[40px] flex items-center justify-center text-white w-full font-inter font-semibold my-[20px]"
					onClick={getSubscription}
					disabled
				>
					Current Plan
				</button>
			) : (
				<button
					type="submit"
					className="get-early-access-button bg-ld-purple rounded-[6px] h-[40px] flex items-center justify-center text-white w-full font-inter font-semibold my-[20px]"
					onClick={getSubscription}
					disabled={isLoading}
				>
					{current_plan_ind === -1 ? `Subscribe to ${plan.name}` : `${['Downgrade', 'Upgrade'][Number(current_plan_ind < planInd)]} to ${plan.name}`}
				</button>
			)}
    </div>
  )
}

export default Plan;
